import React from 'react'
import '../../assets/css/style.css'
import map_img from '../../assets/img/map.png'
export default function LocationMap() {
  return (
    <div className=' my-10 px-6 md:px-20 lg:px-20'>
   <span class="mb-10  block text-center text-xl font-bold text-[#23307F]">
            Our Locations
              <div>
              <p className=" inline-block lg:px-10 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-black">
               </p></div>
              </span>
    
    <section className=''>
        <div className="rt-container">
              <div className="col-rt-12">
                  <div className="Scriptcontent">
                  
           
    <div className="distribution-map">
        <img src={map_img}  alt="" />
       
       
        <button className="map-point" style={{top:'33%',left:'46%'}}>
            <div className="content ">
                <div className="centered-y">
                <div>
                    <span class="inline-block p-3 text-[#23307d] rounded-full bg-blue-100/80 dark:bg-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>
                    </span>
                    
                    <h2 class="mt-4 text-[11px] lg:text-base font-medium text-white dark:text-white">Aero Aegis Ltd (Corporate Office)</h2>
                    <p class="mt-2 text-sm px-1 text-white dark:text-gray-400">128 City Road, London, United Kingdom, EC1V 2NX</p>
                    <p class="mt-2 text-sm text-blue-500 dark:text-blue-400">+44 7888 863174</p>
                </div>
                </div>
            </div>
        </button>
     
        <button className="map-point" style={{top:'40%',left:'27%'}}>
            <div className="content px-14 lg:px-0">
                <div className="centered-y">
                <div >
                    <span class="inline-block p-3 text-[#23307d] rounded-full bg-blue-100/80 dark:bg-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>
                    </span>
                    
                    <h2 class="mt-4 text-[11px] lg:text-base font-medium text-white dark:text-white">Aero Aegis Inc (HQ)</h2>
                    <p class="mt-2 text-[10px] lg:text-sm text-white dark:text-gray-400">8, The Green, STE A, Dover, Delaware 19901</p>
                    <p class="mt-2 text-sm text-blue-500 dark:text-blue-400"> +1 (302) 497-4435</p>
                </div>
                </div>
            </div>
        </button>
        <button className="map-point" style={{top:'57%',left:'67%'}}>
            <div className="content px-16 lg:px-0">
                <div className="centered-y ">
                <div >
                    <span class="inline-block p-3 text-[#23307d] rounded-full bg-blue-100/80 dark:bg-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>
                    </span>
                    
                    <h2 class="mt-4 text-[10px] lg:text-base font-medium text-white dark:text-white">AeroAegis Pvt Ltd (Development Center)</h2>
                    <p class="mt-2 text-[10px]  lg:text-sm  text-white dark:text-gray-400">1509, 5th stage, 2nd cross, BEML layout, R.R.Nagar, Bangalore, Karnataka</p>
                    <p class="mt-2 text-sm text-blue-500 dark:text-blue-400"> 02269718456</p>
                </div>
                </div>
            </div>
        </button>
    
    </div>
    

               
            </div>
        </div>
        </div>
    </section>
         
    
   
    
     
    </div>
  )
}
