import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo_img from "../assets/img/logo.png";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  
  // dropdown disapper
  const handleClick = () => {
    const elem = document.activeElement;
    if (elem) {
      elem?.blur();
    }
  };
  // Sticky Header
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const isScrolled = scrollPosition > 50;

      setIsSticky(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerClass = isSticky
    ? "fixed top-0 left-0 w-full bg-white shadow-md z-50  transition-all duration-300 ease-in-out transform translate-y-0 "
    : "";

  return (
   
      <div className={headerClass}>
        <div className="px-4 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="relative flex items-center justify-between">
            <a href="/" >
              <img src={logo_img} className=" w-24 lg:w-36" ></img>
            </a>
          

            <ul className="flex items-center hidden space-x-8 lg:flex">

            <div className="dropdown dropdown-hover">
                <ul>
                  <li>
                    <a className="flex cursor-pointer font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-[#2f3fa3]">
                    About Us
                      <svg
                        className="w-2.5 h-1.2 ms-2 mt-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </a>
                  </li>
                  <ul
                    tabIndex={3}
                    className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                   

                    <Link to="/aeroaegis-capabilities">
                      {" "}
                      <li onClick={handleClick}>
                        {" "}
                        <a>AeroAegis Capabilities</a>
                      </li>
                    </Link>
                    <Link to="/discover-aeroaegis">
                      <li onClick={handleClick}>
                        <a>Discover AeroAegis</a>
                      </li>
                    </Link>
                    <Link to="/our-values">
                      <li onClick={handleClick}>
                        <a>Our Values</a>
                      </li>
                    </Link>
                    <Link to="/leadership-team">
                      <li onClick={handleClick}>
                        <a>Leadership & Team</a>
                      </li>
                    </Link>
                    <Link to="/partnerships-collaborations">
                      {" "}
                      <li onClick={handleClick}>
                        <a>Partnership & Collaborations</a>
                      </li>
                    </Link>
                  </ul>
                </ul>
              </div>

              <div className="dropdown dropdown-hover">
                <ul>
                  <li>
                    <a className="flex cursor-pointer font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-[#2f3fa3]">
                      Products
                      <svg
                        className="w-2.5 h-1.2 ms-2 mt-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </a>
                  </li>
                  <ul
                    tabIndex={0} 
                    className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    <Link to="/maturity-spectrum-360">
                      <li onClick={handleClick}>
                        <a>Maturity Spectrum 360</a>
                      </li>
                    </Link>
                    <Link to="/smartchaos-lab">
                      {" "}
                      <li onClick={handleClick}>
                        <a>SmartChaos Lab</a>
                      </li>
                    </Link>
                    <Link to="/aeropulse-ai">
                      <li onClick={handleClick}>
                        <a>AeroPulse AI</a>
                      </li>
                    </Link>
                    <Link to="/infra-vue">
                      {" "}
                      <li onClick={handleClick}>
                        <a>AeroInfra Vue</a>
                      </li>
                    </Link>
                    <Link to="/sys-ar">
                      <li onClick={handleClick}>
                        <a>AeroSys AR</a>
                      </li>
                    </Link>
                    <Link to="/aeroedge-iiot">
                      {" "}
                      <li onClick={handleClick}>
                        <a>AeroEdge IIoT</a>
                      </li>
                    </Link>
                    <Link to="/products">
                      <li onClick={handleClick}>
                        <a>View All Products </a>
                      </li>
                    </Link>
                   
                  </ul>
                </ul>
              </div>

              <div className="dropdown dropdown-hover">
                <ul>
                  <li>
                    <a className="flex cursor-pointer font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-[#2f3fa3]">
                      Solutions
                      <svg
                        className="w-2.5 h-1.2 ms-2 mt-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </a>
                  </li>
                  <ul
                    tabIndex={1}
                    className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    <Link to="/ai-ml-solutions">
                      <li onClick={handleClick}>
                        <a>AI & ML Solutions</a>
                      </li>
                    </Link>
                    <Link to="/data-insights-analytics">
                      <li onClick={handleClick}>
                        <a>Data Insights & Analytics</a>
                      </li>
                    </Link>
                    <Link to="/blockchain-solution">
                      {" "}
                      <li onClick={handleClick}>
                        <a>Blockchain Solution</a>
                      </li>
                    </Link>
                    {/* <Link to="/vfx-animation">
                      {" "}
                      <li onClick={handleClick}>
                        <a>VFX & Animation</a>
                      </li>
                    </Link> */}
                  </ul>
                </ul>
              </div>

              <div className="dropdown dropdown-hover">
                <ul>
                  <li>
                    <a className="flex cursor-pointer font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-[#2f3fa3]">
                    Consulting
                      <svg
                        className="w-2.5 h-1.2 ms-2 mt-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </a>
                  </li>
                  <ul
                    tabIndex={2}
                    className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    <Link to="/automation-system-integration">
                      {" "}
                      <li onClick={handleClick}>
                        <a>Automation & System Integration</a>
                      </li>
                    </Link>
                    <Link to="/cloud-solutions-migration">
                      <li onClick={handleClick}>
                        <a>Cloud Solutions & Migration</a>
                      </li>
                    </Link>
                    <Link to="/operational-excellence-with-sre-devops">
                      <li onClick={handleClick}>
                        <a>Operational Excellence with SRE & DevOps</a>
                      </li>
                    </Link>
                  </ul>
                </ul>
              </div>

              {/* <div className="dropdown dropdown-hover">
                <ul>
                  <li>
                    <a className="flex cursor-pointer font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-[#2f3fa3]">
                      Research and development
                      <svg
                        className="w-2.5 h-1.2 ms-2 mt-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                         />
                      </svg>
                    </a>
                  </li>
                  <ul
                    tabIndex={0} 
                    className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    <Link to="/quantum-computing">
                      <li onClick={handleClick}>
                        <a>Quantum Computing</a>
                      </li>
                    </Link>
                    <Link to="/ai-and-machine-learning">
                      {" "}
                      <li onClick={handleClick}>
                        <a>AI and Machine Learning</a>
                      </li>
                    </Link>
                    <Link to="/ar-vr">
                      <li onClick={handleClick}>
                        <a>AR and VR</a>
                      </li>
                    </Link>
                    <Link to="/blockchain-technology">
                      {" "}
                      <li onClick={handleClick}>
                        <a>Blockchain Technology</a>
                      </li>
                    </Link>
                    <Link to="/iot-edge">
                      <li onClick={handleClick}>
                        <a>IOT and Edge Computing</a>
                      </li>
                    </Link>
                  </ul>
                </ul>
              </div>               */}

              <div className="dropdown dropdown-hover">
                <ul>
                  <li>
                    <a className="flex cursor-pointer font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-[#2f3fa3]">
                    Resources
                      <svg
                        className="w-2.5 h-1.2 ms-2 mt-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </a>
                  </li>
                  <ul
                    tabIndex={3}
                    className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    <Link to="/white-papers">
                      {" "}
                      <li onClick={handleClick}>
                        <a>Whitepapers</a>
                      </li>
                    </Link>
                   
                 
                  </ul>
                </ul>
              </div>
              
            </ul>
            <ul className="flex items-center hidden space-x-8 lg:flex">
              <Link to="/contact-us">
                {" "}
                <li>
                  <a
                    className="inline-flex items-center justify-center w-full h-12 px-6 font-normal tracking-wide text-white transition duration-200 rounded shadow-md bg-[#252f80] hover:bg-slate-800 focus:shadow-outline focus:outline-none"
                    aria-label="Contact Us"
                    title="Contact Us"
                  >
                    Contact Us
                  </a>
                </li>
              </Link>
            </ul>
            <div className="lg:hidden">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-[#2f3fa3] focus:bg-[#2f3fa3]"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
              {isMenuOpen && (
                <div className="absolute top-0 left-0 w-full z-10">
                  <div className="p-5 bg-white border rounded shadow-sm">
                    <div className="flex items-center justify-between mb-4">
                      <a href="/" className="inline-flex items-center">
                        <img src={logo_img} width={100}></img>
                      </a>
                      <div>
                        <button
                          aria-label="Close Menu"
                          title="Close Menu"
                          className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <nav >
                      
                      <ul className="space-y-4 flex flex-col">

                        

                      <div className="dropdown dropdown-bottom">
                        <div className=" flex ">
                        <div tabIndex={3} className="font-medium cursor-pointer hover:text-[#2d3ea5] text-gray-700 ml-10">About Us
                        </div>
                        <svg
                        className="w-2.5 h-1.2 ms-2 mt-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg></div>
                        <ul tabIndex={3} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                        <Link to="/aeroaegis-capabilities">
                      <li onClick={handleClick}>
                        <a>AeroAegis Capabilities</a>
                      </li>
                    </Link>
                        <Link to="/discover-aeroaegis">
                      <li onClick={handleClick}>
                        <a>Discover AeroAegis</a>
                      </li>
                    </Link>
                    <Link to="/our-values">
                      <li onClick={handleClick}>
                        <a>Our Values</a>
                      </li>
                    </Link>
                    <Link to="/leadership-team">
                      <li onClick={handleClick}>
                        <a>Leadership & Team</a>
                      </li>
                    </Link>
                    <Link to="/partnerships-collaborations">
                      {" "}
                      <li onClick={handleClick}>
                        <a>Partnership & Collaborations</a>
                      </li>
                    </Link>
                        </ul>
                      </div>


                      <div className="dropdown dropdown-bottom">
                        <div className="flex">
                        <div tabIndex={0} className="font-medium cursor-pointer hover:text-[#2d3ea5] text-gray-700 ml-10">Products
                        </div>
                        <svg
                        className="w-2.5 h-1.2 ms-2 mt-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg></div>
                        <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                        <Link to="/maturity-spectrum-360">
                      <li>
                        <a>Maturity Spectrum 360</a>
                      </li>
                    </Link>
                    <Link to="/smartchaos-lab">
                      {" "}
                      <li onClick={handleClick}>
                        <a>SmartChaos Lab</a>
                      </li>
                    </Link>
                    <Link to="/aeropulse-ai">
                      <li onClick={handleClick}>
                        <a>AeroPulse AI</a>
                      </li>
                    </Link>
                    <Link to="/infra-vue">
                      {" "}
                      <li onClick={handleClick}>
                        <a>AeroInfra Vue</a>
                      </li>
                    </Link>
                    <Link to="/sys-ar">
                      <li onClick={handleClick}>
                        <a>AeroSys AR</a>
                      </li>
                    </Link>
                    
                    <Link to="/products">
                      <li onClick={handleClick}>
                        <a>View All Products </a>
                      </li>
                    </Link>
                   
                        </ul>
                      </div>
                        <div className="dropdown dropdown-bottom">
                        <div className=" flex ">
                        <div tabIndex={1} className="font-medium cursor-pointer hover:text-[#2d3ea5] text-gray-700 ml-10">
                        Solutions
                        </div>
                        <svg
                        className="w-2.5 h-1.2 ms-2 mt-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg></div>
                        <ul tabIndex={1} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                      <Link to="/ai-ml-solutions">
                      <li onClick={handleClick}>
                        <a>AI & ML Solutions</a>
                      </li>
                    </Link>
                    <Link to="/data-insights-analytics">
                      <li onClick={handleClick}>
                        <a>Data Insights & Analytics</a>
                      </li>
                    </Link>
                    <Link to="/blockchain-solution">
                      {" "}
                      <li onClick={handleClick}>
                        <a>Blockchain Solution</a>
                      </li>
                    </Link>
                    <Link to="/vfx-animation">
                      {" "}
                      <li onClick={handleClick}>
                        <a>VFX & Animation</a>
                      </li>
                    </Link>
                        </ul>
                      </div>

                        <div className="dropdown dropdown-bottom">
                        <div className=" flex ">
                        <div tabIndex={2} className="font-medium cursor-pointer hover:text-[#2d3ea5] text-gray-700 ml-10">Consulting
                        </div>
                        <svg
                        className="w-2.5 h-1.2 ms-2 mt-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg></div>
                        <ul tabIndex={2} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                        <Link to="/automation-system-integration">
                      <li onClick={handleClick}>
                        <a>Automation & System Integration</a>
                      </li>
                    </Link>
                    <Link to="/cloud-solutions-migration">
                      <li onClick={handleClick}>
                        <a>Cloud Solutions & Migration</a>
                      </li>
                    </Link>
                    <Link to="/operational-excellence-with-sre-devops">
                      <li onClick={handleClick}>
                        <a>Operational Excellence with SRE & DevOps</a>
                      </li>
                    </Link>
                        </ul>
                      </div>

                      <div className="dropdown dropdown-bottom">
                        <div className=" flex ">
                        <div tabIndex={2} className="font-medium cursor-pointer hover:text-[#2d3ea5] text-gray-700 ml-10">Resources
                        </div>
                        <svg
                        className="w-2.5 h-1.2 ms-2 mt-1"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg></div>
                        <ul tabIndex={2} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
                        <Link to="/white-papers">
                      <li onClick={handleClick}>
                        <a>Whitepapers</a>
                      </li>
                    </Link>
              
                 
                        </ul>
                      </div>

                       
                       
                    
                      <div>  <span>
                     <a
                        href="/contact-us"
                        aria-label="Contact Us"
                        title="Contact Us"
                        className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-[#2d3ea5] ml-10"
                      >
                        Contact Us
                      </a>
                     </span></div>
                   
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
 
  );
}
